import {initializeApp} from 'firebase/app';

export const VIDEO_SIZE = {'200 X 150': {width: 300, height: 250}};

const firebaseConfig = {
  apiKey: 'AIzaSyBXfQNrP1UJt8AT2-zpNBu4D1vUsbgWBPk',
  authDomain: 'flapping-enter.firebaseapp.com',
  projectId: 'flapping-enter',
  storageBucket: 'flapping-enter.appspot.com',
  messagingSenderId: '163927581550',
  appId: '1:163927581550:web:88305d3e4a36f7ad5a977f',
  measurementId: 'G-Z87ZFTR0MV',
};

export const STATE = {
  camera: {sizeOption: '200 X 150'},
  backend: 'mediapipe-gpu',
  flags: {},
  leftElbowDirection: 'upwards',
  rightElbowDirection: 'upwards',
  previousPosition: {
    rightElbow: {y: 0},
    leftElbow: {y: 0},
  },
  minTravelDistance: 4,
  minScore: 0.8,
  firebaseApp: initializeApp(firebaseConfig),
  highscores: [],
  audioElement: new Audio('https://storage.googleapis.com/flapping-enter.appspot.com/chill-song.mp3'),
};
